.content-card-container {
  height: 100%;
  padding-top: 8rem;

  .top-icon-container {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
  }

  h3 {
    text-align: center;
  }

  .top-icon {
    width: 9rem;
    position: absolute;
    top: -5rem;
    z-index: 9997;
    transform: translate(-50%);
  }

  .square-content {
    border-radius: 50%;
    position: absolute;
    top: -50%;
    left: 50%;
    width: 100%;
    padding-bottom: 100%; /* This creates a 1:1 aspect ratio (height = width) */
    background-color: #F0ECFF;
  }

  .icon-container {
    width: 100%;
    height: 5rem;
  }

  .content-card{
    height: 100%;

    p{
      text-align: justify;
    }
  }
}