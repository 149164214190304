@import 'stylesheets/index.scss';

.image-display {
  position: relative;
  img {
    width: 100%;
    object-fit: contain;
  }
  .react-multiple-carousel__arrow--right {
    right: 0;
  }

  .react-multiple-carousel__arrow--left {
    left: 0;
  }

  .selected {
    border: 2px solid $primary;
  }

  .bordered {
    border: 2px solid #f8f8f8;
  }
}