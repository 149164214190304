.login-bg{
  background-size: cover;

  .login-container{
    width: 100%;
    max-width: 35rem;
    background-color: rgba(255,255,255, 0.5);
      
    .ant-card-body{
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      background-color: rgba(255,255,255, 0.5);

      .logo{
        width: 10rem;
        margin: 1rem;
      }

      .ant-btn{
        width: 100%;
      }
    }
  }
}