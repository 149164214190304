.banner-configuration{
  .square-content {
    width: 100%;
    padding-bottom: 100%; /* This creates a 1:1 aspect ratio (height = width) */
    background-color: #f5f5f5;
    position: relative;

    img{
      position: absolute;
      width: 100%;
      height: 100%; 
      object-fit: contain;
    }
  }
  .util-overlay {
    top: 0;
    left: 0;
    z-index: 10;
    width: 100%;
    height: 100%;
    position: absolute;
    display: none;
    background-color: rgba($color: #000000, $alpha: 0.5)
  }

  .square-content:hover .util-overlay {
    display: block;
  }
}
