.product-item {
  &.square-content {
    top: -50%; /* Move half of the content outside on the top edge */
    left: 50%;
    width: 100%;
    padding-bottom: 100%; /* This creates a 1:1 aspect ratio (height = width) */

    .content-inside {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      height: 100%;

      .product-image {
        border: 1px solid #ececec;
        border-radius: 20px;
        position: relative;
        overflow: hidden;
        height: 100%;
        .learn-more-btn {
          position: absolute;
          bottom: 0;
          left: 0;
          border-radius: 0;
          border-top-right-radius: 20px;
        }
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;

        &.round-corner {
          border-radius: 20px;
        }
      }
    }
  }
}