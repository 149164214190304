.dashboard-container{
  width: 100%;
  height: 100%;
}

.dashboard-content-container{
  display: flex;
  height: calc(100% - 3.5rem);
}

.dashboard-content{
  width: 100%;
  padding: 1rem;
  overflow-y: scroll;
}

.dashboard-content-container .ant-menu {
  height: 100%;
  max-width: 256px;
}