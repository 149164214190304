.text-image-container {
  .text-part {
    text-align: justify;
    order: 2
  }

  .image-part {
    order: 1;
    margin-bottom: 2rem;
  }
}

.product-item-slider {
  .react-multiple-carousel__arrow--right {
    right: 0;
  }

  .react-multiple-carousel__arrow--left {
    left: 0;
  }
}

@media screen and (min-width: '992px'){
  .text-image-container {
    .text-part {
      order: 1
    }
  
    .image-part {
      order: 2
    }
  }
}