.product-details{
  .product-description{
    text-align: justify;
  }

  .ant-btn{
    border-radius: 30px;
  }

  .catalogue-btn{
    font-size: 1.5rem;
    padding: 1.5rem;
  }
}