@import 'stylesheets/index.scss';

.outer-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: scroll;

  p {
    font-size: 1.5rem !important;
  }

  .header-menu {
    background-color: rgba(255, 255, 255, 0.6);
    height: 60px;
    width: 70%;
    max-width: 768px;
    border-radius: 50px;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    z-index: 9999;
    padding: 10px 30px;
    display: flex;
    justify-content: space-between;
  
    .logo {
      height: 100%;
      object-fit: contain;
      cursor: pointer;
    }
    .ant-menu {
      background-color: transparent;
      border-bottom: none;
      color: unset;
      display: none;

      @media screen and (min-width: 768px) {
        display: flex !important;
      }
  
      .ant-menu-item {
        display: flex;
        align-items: center;
      }
    }

    .mobile-menu {
      display: flex;
      @media screen and (min-width: 768px) {
        display: none !important;
      }
    }
  }
  
  .content-container {
    flex: 1;
    margin: -5rem 0 0;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;

    h1, h2, h3, h4, h5, h6 {
      color: #505050;
      margin-bottom: 2rem;
    }

    p {
      color: #505050;
      line-height: 3rem;
    }

    .ant-row {
      margin: 2.5rem 0 4rem;
    }

    .ant-row.ant-form-item-row{
      margin: 0;
    }

    .ant-tag{
      border-radius: 30px;
    }

    .ant-btn-success{
      background-color: #28A745;
      color: #FFFFFF;
    }

    .full-width-container {
      margin: 0 -24px 4rem;
      padding: 0 24px;
    }
  }

  .footer-container {
    padding: 3rem;

    .footer-content {
      display: flex;
      justify-content: left;
      text-align: justify;
    }
  }

  .primary-theme {
    background-color: $primary;
    color: #FFFFFF !important;

    h1, h2, h3, h4, h5, h6, p {
      color: #FFFFFF !important;
    }
  }

  .secondary-theme {
    background-color: $secondary;
  }
}

.rounded-button {
  padding: 0 2rem;
  border-radius: 50px;
}

.banner-image{
  width: 100%;
  height: 80vh;
  object-fit: cover;
}

@media screen and (min-width: '768px'){
  .footer-container {
    padding: 3rem 10rem !important;

    .footer-content {
      justify-content: center !important;
    }
  }
}