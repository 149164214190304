@import 'stylesheets/index.scss';

.contact-us {
  overflow: hidden;

  .ant-card-body {
    padding: 0;

    .ant-row {
      margin: 0;

      .left-part {
        padding: 5rem;
        background-color: $primary;
        color: #ffffff;

        h2 {
          color: #ffffff;
        }
      }
      .right-part {
        padding: 5rem;
      }
    }
  }
}